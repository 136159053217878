<template>
  <!-- Footer -->
  <footer class="text-center text-lg-start bg-light text-muted">
    <!-- Section: Social media -->
    <section
      class="d-flex justify-content-center justify-content-lg-between p-4 border-bottom"
    >
      <!-- Left -->
      <div class="me-5 d-none d-lg-block">
        <span>Connect with us</span>
      </div>
      <!-- Left -->

      <!-- Right -->
      <div>
        <a
          href="https://www.facebook.com/1gorkha.khukuri"
          target="_blank"
          class="me-4 text-reset"
        >
          <i class="bi bi-facebook"></i>
        </a>
        <a
          href="https://www.instagram.com/gorkhakhukuri"
          target="_blank"
          class="me-4 text-reset"
        >
          <i class="bi bi-instagram"></i>
        </a>
      </div>
      <!-- Right -->
    </section>
    <!-- Section: Social media -->

    <!-- Section: Links  -->
    <section class="">
      <div class="container text-center text-md-start mt-5">
        <!-- Grid row -->
        <div class="row mt-3">
          <!-- Grid column -->
          <div class="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
            <!-- Content -->
            <h6 class="text-uppercase fw-bold mb-4">
              <img
                style="width: 70px"
                class="main-logo"
                src="../../public/img/logo-tra-bg.png"
                alt="logo"
              />
              Gorkha & Khukuri
            </h6>
          </div>
          <!-- Grid column -->

          <!-- Grid column -->
          <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4"></div>
          <!-- Grid column -->

          <!-- Grid column -->
          <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
            <!-- Links -->
            <h6 class="text-uppercase fw-bold mb-4">Opening times</h6>
            <p><b>Monday</b><br />Closed</p>
            <p><b>Tuesday to Sunday</b> <br />10:00am to 09:00pm</p>
          </div>
          <!-- Grid column -->

          <!-- Grid column -->
          <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
            <!-- Links -->
            <h6 class="text-uppercase fw-bold mb-4">Contact</h6>
            <p>Newton Park <br />LD3 8PA <br />Brecon</p>
            <p>
              <i class="bi bi-envelope"></i>
              gorkha_khukuri@hotmail.com
            </p>
            <p><i class="bi bi-phone"></i> +44-7368360568</p>
          </div>
          <!-- Grid column -->
        </div>
        <!-- Grid row -->
      </div>
    </section>
    <!-- Section: Links  -->

    <!-- Copyright -->
    <div class="text-center p-4" style="background-color: rgba(0, 0, 0, 0.05)">
      © {{ this.currentYear }} Copyright:
      <a
        class="text-reset fw-bold"
        href="https://www.linkedin.com/in/chitralimbu/"
        >Chitra Limbu</a
      >
    </div>
    <!-- Copyright -->
  </footer>
  <!-- Footer -->
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>

<style scoped></style>
