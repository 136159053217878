<template>
  <div id="gallery-container">
    <div id="gallery-wrapper">
      <div class="row row-cols-3">
        <div
          v-for="(image, index) in this.images"
          :key="image.title"
          class="gal-img-container"
          @click="modalOpen(index)"
        >
          <a href="#">
            <div
              class="gal-img-wrapper"
              :style="{ backgroundImage: 'url(' + image.image + ')' }"
              data-bs-toggle="modal"
              data-bs-target="#galleryModal"
            ></div>
          </a>
        </div>
      </div>
    </div>
    <div class="modal fade" id="galleryModal">
      <div class="modal-dialog modal-fullscreen">
        <div class="modal-content">
          <!-- Modal Header -->
          <div class="modal-header">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
            ></button>
          </div>

          <!-- Modal body -->
          <div class="modal-body">
            <vueper-slides
              ref="vueperslides1"
              :touchable="false"
              fade
              :autoplay="false"
              :bullets="false"
              @slide="
                $refs.vueperslides2.goToSlide($event.currentSlide.index, {
                  emit: false,
                })
              "
              fixed-height="75vh"
            >
              <vueper-slide
                v-for="(slide, i) in slides"
                :key="i"
                :image="slide.image"
              >
              </vueper-slide>
            </vueper-slides>

            <vueper-slides
              class="no-shadow thumbnails"
              ref="vueperslides2"
              @slide="
                $refs.vueperslides1.goToSlide($event.currentSlide.index, {
                  emit: false,
                })
              "
              :visible-slides="slides.length"
              fixed-height="75px"
              :bullets="false"
              :touchable="false"
              :gap="2.5"
              :arrows="false"
            >
              <vueper-slide
                v-for="(slide, i) in slides"
                :key="i"
                :image="slide.image"
                @click="$refs.vueperslides2.goToSlide(i)"
              >
              </vueper-slide>
            </vueper-slides>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";

export default {
  name: "Gallery",
  components: { VueperSlides, VueperSlide },
  data() {
    return {
      slides: this.images,
    };
  },
  props: {
    images: {
      type: Array,
    },
  },
  methods: {
    async modalOpen(index) {
      this.$refs.vueperslides2.goToSlide(index);
    },
  },
  mounted() {
    console.log(this.images);
  },
};
</script>

<style scoped>
#gallery-container {
  width: 100%;
  color: white;
}

.gal-img-container {
  height: 450px;
  overflow: hidden;
  padding: 5px;
  transition: transform 0.5s;
}

.gal-img-container:hover {
  transform: scale(1.2);
}

.gal-img-wrapper {
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.thumbnails {
  margin: auto;
}

.thumbnails .vueperslide {
  box-sizing: border-box;
  border: 1px solid #fff;
  transition: 0.3s ease-in-out;
  opacity: 0.7;
  cursor: pointer;
}

.thumbnails .vueperslide--active {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
  opacity: 1;
  border-color: #000;
}

.modal-dialog {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 992px) {
  .gal-img-container {
    height: 20vh;
  }

  .modal-dialog {
    width: 100%;
  }
}
</style>
