<template>
  <SocialMedia />
  <div id="container">
    <div id="wrapper">
      <div id="navbar-container">
        <nav class="navbar navbar-expand-lg navbar fixed-top">
          <div class="container-fluid">
            <b
              ><a class="navbar-brand nav-a" href="javascript:void(0)"
                >Gorkha &amp; Khukuri
              </a></b
            >
            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#mynavbar"
            >
              <b
                ><span
                  class="navbar-toggler-icon bi bi-list navbar-toggle"
                  style="color: black"
                ></span
              ></b>
            </button>
            <div class="collapse navbar-collapse" id="mynavbar">
              <ul class="navbar-nav ms-auto">
                <li class="nav-item">
                  <a
                    class="nav-link nav-a"
                    href="#"
                    v-on:click.prevent="scrollTo('ourmenu')"
                    >Menu</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link nav-a"
                    href="#"
                    v-on:click.prevent="scrollTo('gallery')"
                    >Gallery</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link nav-a"
                    href="#"
                    v-on:click.prevent="scrollTo('contactus')"
                    >Contact Us</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link nav-a"
                    href="#"
                    v-on:click.prevent="scrollTo('map')"
                    >Find Us</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
      <div id="main-banner-container">
        <div id="main-banner-wrapper">
          <div id="logo-container" class="center">
            <img
              class="main-logo"
              src="../public/img/logo-white-bg.jpg"
              alt="logo"
            />
          </div>
        </div>
      </div>
      <div id="main-gallery">
        <div id="main-wrapper">
          <vueper-slides autoplay>
            <vueper-slide
              v-for="(slide, i) in slides"
              :key="i"
              :image="slide.image"
            >
              <template #content>
                <div class="slideshow-text">
                  <p class="slide-title">{{ slide.title }}</p>
                  <p class="slide-content">{{ slide.content }}</p>
                </div>
              </template>
            </vueper-slide>
          </vueper-slides>
        </div>
      </div>
      <div id="contact-container" ref="contactus">
        <div id="contact-wrapper">
          <div class="row">
            <div class="col">
              <a href="tel:+447368360568" class="contact-a"
                ><i class="bi bi-telephone contact-item"></i
              ></a>
            </div>
            <div class="col">
              <a
                href="https://www.facebook.com/1gorkha.khukuri"
                class="contact-a"
                target="_blank"
                ><i class="bi bi-facebook contact-item"></i
              ></a>
            </div>
            <div class="col">
              <a
                href="https://www.instagram.com/gorkhakhukuri/"
                class="contact-a"
                target="_blank"
                ><i class="bi bi-instagram contact-item"></i
              ></a>
            </div>
            <div class="col">
              <a href="mailto:gorkha_khukuri@hotmail.com" class="contact-a"
                ><i class="bi bi-envelope contact-item"></i
              ></a>
            </div>
          </div>
        </div>
      </div>
      <div id="gallery-container" ref="gallery">
        <div id="gallery-wrapper">
          <Gallery :images="this.images" />
        </div>
      </div>
      <div id="menu-container" ref="ourmenu">
        <div id="menu-wrapper" style="width: 100%">
          <img
            src="../public/img/menu/nepali2.jpg"
            style="width: 100%"
            alt="nepalimenu"
          />
          <img
            src="../public/img/menu/british2.jpg"
            style="width: 100%"
            alt="britishmenu"
          />
        </div>
      </div>
      <div id="map-container" ref="map">
        <div id="map-wrapper">
          <div class="mapouter">
            <div class="gmap_canvas">
              <iframe
                width="100%"
                height="500"
                id="gmap_canvas"
                src="https://maps.google.com/maps?q=Newton%20House,%20Newton%20Park,%20Brecon%20LD3%208PA&t=k&z=17&ie=UTF8&iwloc=&output=embed"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
      <div id="footer-container">
        <div id="footer-wrapper">
          <Footer />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
import Gallery from "@/components/Gallery";
import Footer from "@/components/Footer";
import SocialMedia from "./components/SocialMedia.vue";

export default {
  name: "App",
  components: { VueperSlides, VueperSlide, Gallery, Footer, SocialMedia },
  props: {
    title: {
      default: "Gorkha & Khukuri",
      type: String,
    },
  },
  data() {
    return {
      pauseOnHover: true,
      autoPlaying: true,
      duration: 500,
      images: [
        {
          title: "thali_set",
          image: require("../public/img/thali.jpg"),
        },
        {
          title: "aloo",
          image: require("../public/img/food/aloo.jpg"),
        },
        {
          title: "chatpate",
          image: require("../public/img/food/chatpate.jpg"),
        },
        {
          title: "chilli_chicken",
          image: require("../public/img/food/chillichicken.jpg"),
        },
        {
          title: "chowmein",
          image: require("../public/img/food/chowmein.jpg"),
        },
        {
          title: "fried_rice",
          image: require("../public/img/food/fried-rice.jpg"),
        },
        {
          title: "momo",
          image: require("../public/img/food/momo.jpg"),
        },
        {
          title: "sukuti",
          image: require("../public/img/food/sukuti.jpg"),
        },
      ],
      slides: [
        {
          title: "Thali set",
          content: "Traditional Nepalese Main meal",
          image: require("../public/img/thali.jpg"),
        },
        {
          title: "Golf Course",
          content: "Located at Brecon Golf Course",
          image: require("../public/img/local/main.jpg"),
        },
        {
          title: "Momo",
          content: "Traditional Homemade Momo",
          image: require("../public/img/food/momo.jpg"),
        },
        {
          title: "Sukuti",
          content: "Nepalese dried meat snack",
          image: require("../public/img/food/sukuti.jpg"),
        },
        {
          title: "Chatpate",
          content: "Spicy Nepalese snack",
          image: require("../public/img/food/chatpate.jpg"),
        },
        {
          title: "Chowmein",
          content: "Traditional Nepalese Chowmein",
          image: require("../public/img/food/chowmein.jpg"),
        },
        {
          title: "Chilli chicken",
          content: "Spicy chicken snack",
          image: require("../public/img/food/chillichicken.jpg"),
        },
      ],
    };
  },
  methods: {
    async scrollTo(dest) {
      const offsetTop = this.$refs[dest].offsetTop;
      window.scrollTo(0, offsetTop - 50);
    },
    onInit: () => {
      console.log("lightGallery has been initialized");
    },
    onBeforeSlide: () => {
      console.log("calling before slide");
    },
  },
};
</script>

<style scoped>
#container {
  width: inherit;
  overflow: hidden;
  /*margin-right: auto;
  margin-left: auto;*/
}

#wrapper {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
}

.navbar {
  width: 100vw;
  /*margin-right: auto;
  margin-left: auto;*/
  padding-right: 100px;
  padding-left: 100px;
}

#main-banner-container {
  /*width: 100vw;
  max-width: 100vw;*/
  margin-top: 40px;
  height: 220px;
}

#main-gallery {
  overflow: hidden;
}

#main-banner-wrapper,
#main-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

#main-banner-wrapper {
  /*background-color: black;*/
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

#logo-container {
}

.center {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}

.main-logo {
  width: 200px;
}

.slideshow-text {
  width: 25%;
  float: right;
  margin: 20px;
  font-family: Avantgarde, TeX Gyre Adventor, URW Gothic L, sans-serif;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 7px 30px 7px 30px;
}

.slide-title {
  font-size: 35px;
  font-weight: bold;
  font-style: italic;
}

.nav-a {
  color: black;
  font-size: 20px;
  font-weight: bold;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.nav-a:active {
  color: black !important;
}

.nav-a:hover {
  color: white;
  background-color: black;
}

.col {
  text-align: center;
}

.nav-link {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.contact-a {
  display: inline-block;
  font-size: 30px;
  color: black;
  transition: transform 0.5s;
}

.contact-a:hover {
  transform: scale(1.3);
  color: saddlebrown;
}

.navbar {
  background-color: white;
  padding-top: 0;
  padding-bottom: 0;
}

#contact-container {
  display: none;
}

@media only screen and (max-width: 992px) {
  .navbar {
    padding-right: 0;
    padding-left: 0;
  }

  #wrapper {
    width: 100%;
  }

  .navbar-toggle {
    color: black;
    font-size: 32px;
    padding: 5px;
  }

  .slide-title {
    font-size: 3vw;
  }

  .slide-content {
    display: none;
    font-size: 1vh;
  }

  .nav-a {
    font-size: 2vh;
    margin-top: 5px;
  }

  .nav-item {
    padding-left: 20px;
  }

  .nav-link {
    padding-left: 20px !important;
  }

  #contact-container {
    display: block;
  }
}
</style>
