<template>
  <div class="icon-bar">
    <a
      href="https://www.facebook.com/1gorkha.khukuri"
      target="_blank"
      class="facebook"
      ><i class="bi bi-facebook contact-item"></i
    ></a>
    <a
      href="https://www.instagram.com/gorkhakhukuri/"
      target="_blank"
      class="instagram"
    >
      <i class="bi bi-instagram contact-item"></i
    ></a>
    <a href="mailto:gorkha_khukuri@hotmail.com" class="email"
      ><i class="bi bi-envelope contact-item"></i
    ></a>
    <a href="tel:+447368360568" class="phone"
      ><i class="bi bi-telephone contact-item"></i
    ></a>
  </div>
</template>

<style scoped>
.icon-bar {
  position: fixed;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.icon-bar a {
  display: block;
  text-align: center;
  padding: 16px;
  transition: all 0.3s;
  color: white;
  font-size: 20px;
}

.icon-bar a:hover {
  background-color: #000;
  width: 200%;
}

.facebook {
  background: #3b5998;
  color: white;
}

.email {
  background: #55acee;
  color: white;
}

.phone {
  background: #dd4b39;
  color: white;
}

.instagram {
  background: #007bb5;
  color: white;
}

.youtube {
  background: #bb0000;
  color: white;
}

.content {
  margin-left: 75px;
  font-size: 30px;
}

@media only screen and (max-width: 992px) {
  .icon-bar {
    display: none;
  }
}
</style>

<script>
export default {
  name: "Social Media",
};
</script>
